const URL = process.env.REACT_APP_API_BASE_URL || "https://sec.ong"



export async function createNote(data, encodedKey) {
  return await fetch(`${URL}/notes/create`, {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      'X-Timestamp': encodedKey
      },
    body: JSON.stringify(data),
  })
}


export async function getNote(noteId, encodedKey) {
    return await fetch(`${URL}/notes/${noteId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-Timestamp': encodedKey
      }
    }); 
}


export async function getPassword(noteId, password, encodedKey) {
    return await fetch(`${URL}/notes/${noteId}?password=${password}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Timestamp': encodedKey
        },
      })
}
  

export async function deleteNote(link) {
    return await fetch(`${URL}/notes/${link}/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      })
}
  

