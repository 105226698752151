import Underlay from "../../Components/Underlay/Underlay";
import Container from "../../ui/Container/Container";
import s from './styles.module.scss'
import { useTranslation } from 'react-i18next';
import Card from '../../Components/Card/Card'
import Header from '../../Components/Header/Header'
import Form from "../../Components/Form/Form";

export default function Root() {
  const { t } = useTranslation();

  return (
    <Container>
      <Header />
      <Underlay>
        <Form />
      </Underlay>
      <div className={s.secondSection}>
        <div className={s.cardsHeader}>{t("howDoesItWork")}</div>
        <div className={s.cardsWrapper}>
          <Card
            cardHeader={t("cardHeader1")}
            cardText={t("cardText1")}
            cardImage="../images/write.png"
          />
          <Card
            cardHeader={t("cardHeader2")}
            cardText={t("cardText2")}
            cardImage="../images/send.png"
          />
          <Card
            cardHeader={t("cardHeader3")}
            cardText={t("cardText3")}
            cardImage="../images/fire.png"
          />
        </div>
      </div>
    </Container>
  );
}
