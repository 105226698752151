import s from './styles.module.scss'

const Input = ({placeholder, name, type, onChangeFunction, value}) => {
    return (
       
     <input
      className={s.input}
      placeholder={placeholder}
      name={name}
      type={type}
      value={value}
      onChange={onChangeFunction}
    />
    )
}

export default Input