import Button from "../../ui/Button/Button";
import s from "./styles.module.scss";
import { useEffect, useState } from "react";
import { deleteNote } from "../../api/api";
import { useTranslation } from 'react-i18next';
import ClipboardJS from "clipboard";
import { useLocation } from "react-router";
import Wallets from "../Wallets/Wallets";


const CreatedLink = ({ link, noteText, createNewNote, notePassword }) => {
  const { t } = useTranslation();

  const location = useLocation()

  function createLink(link) {
    return `${window.location.origin}/${link}${encodeURIComponent(location.hash)}`
  }

  const [copyText, setCopyText] = useState(t('copyLink'))
  const [deletedLink, setDeletedLink] = useState(false)

  useEffect(() => {
    setCopyText(t('copyLink'))
  }, [t])


  new ClipboardJS("#copyBtn");

  function copyToClipboard() {
    setCopyText(t('copied'))
  }

  const deleteLink = () => {

    deleteNote(encodeURIComponent(link))
      .then(function (response) {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        } else {
          return setDeletedLink(true)
        }

      })
      .then(function () {
      })
      .catch(function (error) {
        console.error('There was a problem with the DELETE request:', error);
      });
  }

  return (
    <>
      <div>
        <button className={s.backButton} onClick={createNewNote}>{t('createNewNote')}</button>

        <div className={s.linkWrapper}>
          {
            !deletedLink ?
              <div className={s.linkImgWrapper}>
                <span id="note_link_input">{createLink(link)}</span>

                <img id="copyBtn" data-clipboard-target="#note_link_input" src="/images/copy_1621635.png" alt="Copy" />
              </div>
              :
              <span>{t('linkDeleted')}</span>
          }
          {
            !notePassword ? <span style={{ display: "none" }}></span> :
              <div className={s.linkImgWrapper}>
                <p>{t('password')}:</p>
                <p id="copyPassword">{notePassword}</p>
                <img id="copyBtn" data-clipboard-target="#copyPassword" src="/images/copy_1621635.png" alt="Copy" />
              </div>}
        </div>
        <div className={s.buttonsWrapper}>
          <button id="copyBtn" className={s.copyBtn} data-clipboard-action="copy" data-clipboard-target="#note_link_input" onClick={copyToClipboard}>{copyText}</button>

          <Button text={t('deleteNote')} onClickFunction={deleteLink} disabled={deletedLink} additionalClass="red" />
        </div>
        <Wallets />

        {
          !deletedLink ?
            <div className={s.noteWrapper}>
              <div className={s.header}>{t('noteText')}</div>
              <div className={s.text}>{noteText}</div>
            </div >
            :
            <div className={s.noteWrapper}>
              <div className={s.header}>{t('noteDeleted')}</div>
            </div >
        }



      </div>
    </>
  );
};

export default CreatedLink;
