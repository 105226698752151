import s from './styles.module.scss'

const Underlay = ({children}) => {
    return (
        <div className={s.underlay}>
            {children}
        </div>
    )
}

export default Underlay