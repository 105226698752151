import s from './styles.module.scss'


function Selector({ options, name}) {
  return (
    <div className={s.selectWrapper}>
      <select name={name} className={s.select} >
          {options.map((option) => (
            <option key={option.value} value={option.value} selected={option.selected}>
              {option.label}
            </option>
          ))}
        </select>
    </div>
 
  );
}

export default Selector;
