import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './pages/Root/Root';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './pages/ErrorPage/ErrorPage';
import DonatePage from './pages/DonatePage/DonatePage'
import Note,  {
  loader as noteLoader,
} from './pages/Note/Note';
import "../src/langs/i18n";




const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: ":noteId",
    element: <Note />,
  },
  {
    path: "/donate",
    element: <DonatePage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
