import React from 'react';
import s from "./styles.module.scss";
import Button from "../../ui/Button/Button";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ClipboardJS from "clipboard";
import { useState } from 'react';
import QRCode from "react-qr-code";


const Wallets = () => {
    new ClipboardJS("#copyBtn");

    const { t } = useTranslation();

    const [style, setStyle] = useState(`${s.hidden}`);

  function showQr() {
    setStyle(`${s.qrWrapper}`);
  }

  function hideQr() {
    setStyle(`${s.hidden}`)
  }

    return (
        <div className={s.walletsContainer}>
            <div className={s.walletContainer}>
                <div className={s.wallet}>
                    <p>BTC:</p><p className={s.walletText} id="copy">bc1qyxga2qf0rc70zar9ftxdms7vxct374v6sakskg</p>
                    <img id="copyBtn" data-clipboard-target="#copy" src="/images/copy_1621635.png" alt="Copy" />
                    <img src="/images/qr-code.png" alt="Qr-code" onClick={showQr} />
                    <div className={style} onClick={hideQr}>
            <div className={s.qr}>
              <QRCode value="bc1qyxga2qf0rc70zar9ftxdms7vxct374v6sakskg" size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%", backgroundColor: "#FFFFFF" }} />
            </div>
          </div>
                </div>
            </div>
            <Link to="/donate"><Button text={t('donateBtn')} /></ Link >
        </div>
    )
}

export default Wallets