import s from './styles.module.scss'

const  Card = ({cardHeader, cardText, cardImage}) =>{
    return (
        <div className={s.cardWrapper}>
            <img className={s.cardImg} src={cardImage} src={cardImage}/>
            <div className={s.cardHeader}>
                {cardHeader}
            </div>
            <div className={s.cardText}>
                {cardText}
            </div>

        </div>
    )
}

export default Card