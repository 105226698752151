var forge = require('node-forge');

export function generateKey() {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function encrypt(input, inputKey) {
    var key = forge.util.createBuffer(inputKey);
    var utf8Bytes = forge.util.encodeUtf8(input);
    var base64Input = forge.util.encode64(utf8Bytes);
    var cipher = forge.cipher.createCipher('DES-ECB', key);
    cipher.start();
    cipher.update(forge.util.createBuffer(base64Input));
    cipher.finish();
    var encrypted = cipher.output;
    return forge.util.encode64(encrypted.getBytes());
}

export function decrypt(input, inputKey) {
    var key = forge.util.createBuffer(inputKey);
    var encryptedBytes = forge.util.decode64(input);
    var decipher = forge.cipher.createDecipher('DES-ECB', key);
    decipher.start();
    decipher.update(forge.util.createBuffer(encryptedBytes, 'raw'));
    decipher.finish();
    var base64DecryptedText = decipher.output.toString();
    return forge.util.decodeUtf8(forge.util.decode64(base64DecryptedText));
}
