import { getNote, getPassword } from "../../api/api";
import { useEffect, useState } from "react";
import Underlay from "../../Components/Underlay/Underlay";
import { useLocation, useParams } from "react-router";
import s from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import Button from "../../ui/Button/Button";
import Container from "../../ui/Container/Container";
import Input from "../../ui/Input/Input";
import Header from "../../Components/Header/Header";
import ClipboardJS from "clipboard";
import Wallets from "../../Components/Wallets/Wallets";
import { encrypt, decrypt } from "../../utils/crypto";


export default function Note() {
  const { t } = useTranslation();

  const staticKey = process.env.REACT_APP_STATIC_KEY || "key";
  
  const [note, setNote] = useState("");
  const [password, setPassword] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [copyNote, setCopyNote] = useState(t('copyText')) 
  const [showNote, setShowNote] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [incorrectPasswordMessageShow, setIncorrectPasswordMessageShow] = useState(false) 
  const [copyButtonShow, setCopyButtonShow] = useState(false) 
  new ClipboardJS("#copyBtn");
  
  useEffect(() => {
    setCopyNote(t('copyText'));
    setNote(t('noteNotFound'));
  },[t])
  

  const { noteId } = useParams();
  const location = useLocation()
  const key = location.hash.substring(1);

  useEffect(() => {
    const fetchData = async () => {
    
      try {

        const response = await getNote(`${noteId}`, encrypt(Date.now(), staticKey));

        if (!response.ok) {
          if (response.status === 403) {
            setPasswordRequired(true);
          } 
          else if (response.status === 404) {
            setShowNote(true)
            setNote(t('noteNotFound'));
            setCopyButtonShow(false)
          } else {
            throw new Error('Network response was not ok');
          }
        } else {
          const data = await response.json();
          setNote(decrypt(data.content, key));
          setShowWarning(true)
          setCopyButtonShow(true)
        }
      } catch (error) {
        console.error('There was a problem with the request:', error);
      }

    };

    fetchData();
  }, [location]);

  const openNote = () => {
    setShowWarning(false);
    setShowNote(true);
  };


  const handlePasswordSubmit = () => {

    getPassword(`${noteId}`, password, encrypt(Date.now(), staticKey)) 
      .then((response) => {
        if (!response.ok) {
          if (response.status === 403) {
            setIncorrectPasswordMessageShow(true)
          } 
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setNote(decrypt(data.content, key));
        setShowNote(true);
        setCopyButtonShow(true)
      })
      .catch((error) => {
        console.error('There was a problem with the second request:', error);
      });
  };

  function copyNoteToClipboard() {
    setCopyNote(t('copied'))
  }

  return (
    <Container>
      <Header />

    <Underlay>
      {showWarning && (
        <div>
          <div className={s.cautionWrapper}>{t("caution")}</div>

          <div className={s.cautionButtonWrapper}>
            <Button onClickFunction={openNote} text={t("showNote")} />
          </div>
        </div>
      )}
      {showNote ? (
        <>
        <div className={s.noteWrapper} id="note_input">{note}</div>

        {copyButtonShow &&
        <div className={s.copyNoteButtonWrapper}>

        <button id="copyBtn" className={s.copyBtn} data-clipboard-action="copy" data-clipboard-target="#note_input" onClick={copyNoteToClipboard} >{copyNote}</button>
        </div>
    
        }
        </>
      ) : (
        passwordRequired && (
          <>
          <div className={s.cautionWrapper}>{t('cautionPassword')}</div>

          { incorrectPasswordMessageShow &&
          <div className={s.passwordIncorrect}>{t('incorrectPassword')}</div>

          }

          <div className={s.passwordWrapper}>
           <Input placeholder={t('enterPassword')} value={password} onChangeFunction={(e) => setPassword(e.target.value)}/>
          
          <div className={s.buttonWrapper}> 
               <Button onClickFunction={handlePasswordSubmit} text={t('sendPassword')}/>
          </div>
                
          </div>
        
          </>
        )
      )
    
    }
    <Wallets />   
    </Underlay>
    </Container>
  );
}


