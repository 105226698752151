import s from './styles.module.scss'
import { Link, useLocation } from 'react-router-dom';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

const Header = () => {
    const location = useLocation();

    function handleHeaderClick() {
        if (location.pathname === "/") {
            window.location.reload();
        }
        
    }

    return (
        <>
        <header className={s.header}>
                <Link to="/"><div className={s.logoWrapper} onClick={handleHeaderClick}>
                <img className={s.logoImg} src="../images/logo.svg" alt="logo"/>
                <span>SEC.ONG</span></div>
                </Link>
                <LanguageSwitcher />
        </header>

        </>
    )
}

export default Header