import { useTranslation } from "react-i18next";
import s from "./styles.module.scss";

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleOptionClick = (event) => {
    const selectedOption = event.target.value;

    changeLanguage(selectedOption);
  };

  return (
    <>
      <div className={s.selectWrapper}>
        <select className={s.select} onChange={handleOptionClick}>
          <option className={s.option} value="en">
            English
          </option>
          <option className={s.option} value="fr">
            French
          </option>
          <option className={s.option} value="de">
            German
          </option>
          <option className={s.option} value="ru">
            Russian
          </option>
        </select>
      </div>
    </>
  );
}

export default LanguageSwitcher;
