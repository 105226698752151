import s from './styles.module.scss'
import cn from 'classnames';

const Button = ({text, type, onClickFunction, additionalClass, disabled}) => {
    const buttonClasses = cn(s.button, `${s[additionalClass]}` ); 

    return (
        <button disabled={disabled} className={buttonClasses} type={type} onClick={onClickFunction}>{text}</button>
    )
}

export default Button