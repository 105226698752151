import s from "./styles.module.scss";
import Button from "../../ui/Button/Button";
import Input from "../../ui/Input/Input";
import Selector from "../../ui/Selector/Selector";
import Textarea from "../../ui/Textarea/Textarea";
import { useState } from "react";
import cn from "classnames";
import CreatedLink from "../CreatedLink/CreatedLink";
import Loader from "../Loader/Loader";
import { createNote } from "../../api/api";
import { useTranslation } from 'react-i18next';
import Wallets from "../Wallets/Wallets";
import { generateKey, encrypt } from "../../utils/crypto";

const Form = () => {
  const { t } = useTranslation();

  const staticKey = process.env.REACT_APP_STATIC_KEY || "X9g4AO5f";

  const timeOptions = [
    { value: "60", label: t('1Hour') },
    { value: "120", label: t('2Hours') },
    { value: "240", label: t('4Hours') },
    { value: "480", label: t('8Hours') },
    { value: "1440", label: t('1Day') },
    { value: "2880", label: t('2Days') },
    { value: "10080", label: t('7Days')},
    { value: "43200", label: t('1Month') },
    { value: "259200", label: t('6Months') },
    { value: "525600", label: t('1Year'), selected: true },
    { value: "2628000", label: t('5Years') }
  ];

  const [noteText, setNoteText] = useState("");
  const [notePassword, setNotePassword] = useState("");
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [link, setLink] = useState("");
  const [isPending, setPending] = useState(false);
  const [key, setKey] = useState(generateKey());


  function handleSubmit(e) {
    setPending(true);

    e.preventDefault();
    const formData = new FormData(e.target);
    let data = Object.fromEntries(formData);
    data.content  = encrypt(data.content, key);
    console.log("🚀 ~ handleSubmit ~ key:", staticKey)


    createNote(data, encrypt(key, staticKey))
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        setLink(`${response.note_id}#${key}`);
        setPending(false);
      });
  }

  const handleTextareaChange = (e) => {
    setNoteText(e.target.value);
  };

  const handlePasswordChange = (e) => {
    console.log('e.target', e.target)
    setNotePassword(e.target.value)
  }

  const createNewNote = () => {
    setLink("")
    setNoteText("")
    setNotePassword("")
  }

  return (
    <>
      {isPending ? (
        <div className={s.loaderWrapper}>
          <Loader />
        </div>
      ) : link ? (
        <CreatedLink link={link} isPending={isPending} noteText={noteText} createNewNote={createNewNote} notePassword={notePassword}/>
      ) : (
        <form onSubmit={handleSubmit} className={s.formWrapper}>
          <h1>
          {t('shareMessage')} 
          </h1>

          <Textarea
            placeholder={t('textarea')} 
            name={"content"}
            value={noteText}
            onChange={handleTextareaChange}
          />

          <div className={s.flexWrapper}>
            {optionsOpen ? (
              <div className={s.optionsWrapper}>
                <Input name="password" 
                placeholder={t('password')} 
                value={notePassword} 
                onChangeFunction={handlePasswordChange} />
                <Input name="mail" placeholder={t('email')} type="email" />
                <Selector name="lifetime" options={timeOptions} />
                <button
                  className={cn(s.optionButton, s.close)}
                  onClick={() => setOptionsOpen(false)}
                >
                 {t('hideOptions')}
                </button>
              </div>
            ) : (
              <button
                className={cn(s.optionButton, s.open)}
                onClick={() => setOptionsOpen(true)}
              >
                 {t('openOptions')}
              </button>
            )}

            <div className={s.buttonWrapper}>
              <Button type={"submit"} text={t('createLink')} />
            </div>
          </div>
          <Wallets />
        </form>
      )}
    </>
  );
};

export default Form;
