import React from 'react';
import s from "./styles.module.scss";
import ClipboardJS from "clipboard";
import { useId, useState } from 'react';
import QRCode from "react-qr-code";


const WalletItem = ({ walletName, walletAddress }) => {
  const btnId = useId();
  const textId = useId();
  new ClipboardJS("#" + CSS.escape(btnId));

  const [style, setStyle] = useState(`${s.hidden}`);

  function showQr() {
    setStyle(`${s.qrWrapper}`);
  }

  function hideQr() {
    setStyle(`${s.hidden}`)
  }
  return (
    <div className={s.walletItem}>
      <p>{walletName}:</p>
      <div className={s.walletWrapper}>
        <p id={textId}>{walletAddress}</p>
        <div className={s.imagesWrapper}>
          <img id={btnId} data-clipboard-target={"#" + CSS.escape(textId)} src="/images/copy_1621635.png" alt="Copy" />
          <img src="/images/qr-code.png" alt="Qr-code" onClick={showQr}/>
          <div className={style} onClick={hideQr}>
            <div className={s.qr}>
              <QRCode value={walletAddress} size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%", backgroundColor: "#FFFFFF" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WalletItem